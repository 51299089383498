import IntroComponent from './intro/IntroComponent';

function HomePageComponent() {
    return(
        <div>
            <IntroComponent></IntroComponent>
        </div>
    )
}

export default HomePageComponent;